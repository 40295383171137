import React from "react";
import Layout from "../components/layout";
import SEO from "../components/seo.js";
import { Link } from "gatsby";
import sharedStyles from "../components/shared.module.scss";
import buttonStyles from "../components/buttons.module.scss";

const Welcome = () => {
  return (
    <Layout>
      <div className={sharedStyles.centered}>
        <SEO title="Notion Covers | Welcome" />
        <h2>Thanks for signing up!</h2>
        <p>You've successfully confirmed your email address.</p>
        <Link className={buttonStyles.button} to="/">
          Go home
        </Link>
      </div>
    </Layout>
  );
};

export default Welcome;
